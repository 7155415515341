<template>
  <Card>
    <template #title> Usuários Keycloak </template>
    <template #content>
      <DataTable
        class="p-datatable-sm"
        :loading="loading"
        :value="usuarios"
        :lazy="true"
        stripedRows
        responsiveLayout="scroll">
        <template #header>
          <form @submit.prevent="buscar()">
            <div class="flex justify-content-between">
              <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                  <InputText v-model="campoBusca" placeholder="Buscar" />
                  <Button
                    icon="pi pi-search"
                    type="submit"
                    class="p-button-warning" />
                </div>
              </div>
            </div>
          </form>
        </template>
        <template #empty> Nenhum registro encontrado. </template>
        <Column field="id" header="ID"></Column>
        <Column field="username" header="CPF (usuário)"></Column>
        <Column field="firstName" header="Nome">
          <template #body="slotProps">
            {{ slotProps.data.firstName }}
            {{ slotProps.data.lastName }}
          </template>
        </Column>
        <Column field="email" header="E-mail"></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import UsuarioService from '@/service/UsuarioService'
export default {
  data() {
    return {
      usuarios: null,
      campoBusca: null,
      loading: false,
    }
  },
  created() {
    this.service = new UsuarioService(this.$http)
  },

  mounted() {
    // this.loading = !this.loading
    // this.service.getKeycloakUsers().then((res) => {
    //   this.usuarios = res
    //   this.loading = !this.loading
    // })
  },

  methods: {
    limparBusca() {
      this.campoBusca = null
      this.buscar()
    },
    buscar() {
      this.loading = !this.loading
      this.service.getKeycloakUsers(this.campoBusca).then((res) => {
        this.usuarios = res
        this.loading = !this.loading
      })
    },
  },
}
</script>

<style></style>
